import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import './_style.scss';


class HelpiButton extends PureComponent {

    onClick = (evt) => this.props.onClick(evt);

    generateClasses = () => {
        const { classList } = this.props;

        return `helpi-button ${classList ? classList.join(' ') : ''}`;
    };

    render() {
        const classes = this.generateClasses();
        return (
            !this.props.hidden && <div className="helpi-button-container">
                {
                    this.props.type === 'link' && !this.props.ext &&
                    <NavLink
                        to={this.props.to}
                        className={classes}
                        onClick={this.onClick}
                    >
                        {this.props.label}
                    </NavLink>
                }
                {
                    this.props.type === 'link' && this.props.ext &&
                    <a
                        href={this.props.to}
                        className={classes}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={this.props.onClick ? this.onClick : null}
                    >
                        {this.props.label}
                    </a>
                }
                {
                    this.props.type !== 'link' &&
                    <button
                        type={this.props.type}
                        className={classes}
                        onClick={this.props.onClick ? this.onClick : null}
                        disabled={this.props.disabled}
                    >
                        {this.props.icon ? <span>{this.props.label} {this.props.icon}</span> :this.props.label}
                    </button>
                }
            </div>
        )
    }
}

export default HelpiButton;

HelpiButton.propTypes = {
    onClick: PropTypes.func,
    classList: PropTypes.array,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['button', 'submit', 'link']),
    hidden: PropTypes.bool,
    disabled: PropTypes.bool,
    icon: PropTypes.object
};

HelpiButton.defaultProps = {
    classList: [],
    type: 'button',
    hidden: false,
    disabled: false,
    icon: null
};
