import React, { PureComponent } from "react";
import "./style.scss";
import headquarter from "../../../assets/img/about_us/headquarter.png";
import equality from "../../../assets/img/about_us/equality.png";
import boxWithAHeart from "../../../assets/img/about_us/box-with-a-heart.png";
import marketingPromotion from "../../../assets/img/about_us/marketing-promotion.png";
import team from "../../../assets/img/about_us/team.png";
import PrincipleItem from "./principle-item";

class Principles extends PureComponent {
  render() {
    const items = [
      {
        icon: <img src={headquarter} alt="our principles" />,
        description: " סולידריות אזרחית",
      },
      {
        icon: <img src={equality} alt="our principles" />,
        description: " שווין הזדמנויות",
      },
      {
        icon: <img src={boxWithAHeart} alt="our principles" />,
        description: <span>מעורבות<br/>ושותפות חברתית</span>,
      },
      {
        icon: <img src={marketingPromotion} alt="our principles" />,
        description: " שקיפות",
      },
      {
        icon: <img src={team} alt="our principles" />,
        description:<span>קהילתיות<br/>וקבלת האחר</span>,
      },
    ];

    return (
      <div className="principles main">
        <h1>הערכים שלנו</h1>
        <div className="principles-line">
          {items.map((item, index) => {
            return (
              <PrincipleItem
                key={index}
                icon={item.icon}
                description={item.description}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default Principles;
