import React from "react";

const PrincipleItem = ({ icon, description }) => {
  return (
    <div className="principle-item">
      {icon}
      <p>{description}</p>
    </div>
  );
};

export default PrincipleItem;
