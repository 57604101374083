import React from 'react';
import './style.scss';
import CaseCardSuccess from '../../components/case-card-success';
import SuccessContainer from "../../core/containers/success";
import backgroundImg from '../../assets/img/success-page/Group-1636.svg';
import _ from 'lodash';

class Success extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { successes, whiteLabel } = this.props;

        const headingText = _.isEmpty(whiteLabel) ? `סיפורי הצלחה` : `המתנדבים של ${whiteLabel.name}`;

        return (
            <>
                <div className='backgroundImg-warper'>
                    <div className='backgroundImg-tite-box'>
                        <img src={backgroundImg} className='backgroundImg' />
                        <div className={`success-page-title ${_.isEmpty(whiteLabel) ? '' : 'WL'}`}>
                            {headingText}
                        </div>
                    </div>
                    <div className={`container-success cases-search-view success-page`}>
                        <div className='sub-container'>
                            {successes && successes.length > 0 &&
                                <div className="cases">
                                    {successes.map((item, index) => {
                                        return (
                                            <CaseCardSuccess
                                                id={item.id}
                                                title={item.text}
                                                imgUrl={item.imgUrl}
                                                withButton={false}
                                                key={`success-card-${index}-${item.id}`}
                                            />
                                        );
                                    })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


export default SuccessContainer(Success);
