import React, { PureComponent } from "react";
// import './style.scss';
import PropTypes from "prop-types";

class Landmark extends PureComponent {
  render() {
    const { year, details, background } = this.props;

    return (
      <div className="landmark">
        <div className="details">
          {details.map((detail, i) => (
            <p key={i}>{detail}</p>
          ))}
        </div>
        <span className="year" style={{ background }}>
          {year}
        </span>
      </div>
    );
  }
}
Landmark.propTypes = {
  background: PropTypes.string,
  year: PropTypes.string,
  details: PropTypes.array,
};
export default Landmark;
