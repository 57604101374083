import React, { PureComponent } from "react";
import "./style.scss";
import Landmark from "./landmark";
// import PropTypes from "prop-types";

class Landmarks extends PureComponent {
  render() {
    const events = [
      {
        year: "2015",
        background: "#1abcc4",
        details: [
          "רישום העמותה ברשם העמותות",
          <a
            href={`/files/תעודת מלכר.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {"תעודות רישום עמותה"}
            <br />
            {"אישור ניהול תקין"}
            <br />
            {"סעיף 46"}
          </a>,
        ],
      },
      {
        year: "2016",
        background: "#f57c20",
        details: [
          "יצירת שת״פ אסטרטגי עם משרד העבודה הרווחה והשירותים החברתיים",
          <a
            href="https://www.thesocialprogram.co.il/"
            target="_blank"
            rel="noopener noreferrer"
          >
            השתתפות באקסלרטור 8200 impact
          </a>,
        ],
      },
      {
        year: "2017",
        background: "#ed4c67",
        details: [
          "פלטפורמת Helpi עלתה לאוויר באפריל 2017.",
          "העמותה קיבלה אישור כמוסד ציבורי לעניין סעיף 46א לפקודת מס הכנסה",
        ],
      },
      {
        year: "2018",
        background: "#fcbb14",
        details: [
          "העמותה קיבלה תו מידות לאפקטיביות",
          <a
            href="http://www.midot.org.il/%D7%91%D7%95%D7%90%D7%95%D7%A0%D7%AA%D7%A2%D7%A8%D7%91"
            target="_blank"
            rel="noopener noreferrer"
          >
            תו מידות להורדה
          </a>,
        ],
      },
      {
        year: "2019",
        background: "#56ba6b",
        details: ["העמותה התקבלה למאגר העמותות של עיגול לטובה בינואר"],
      },
    ];

    return (
      <div className="landmarks main">
        <h1>ציון דרך בפעילות העמותה</h1>
        <div className="timeline">
          <div className="vertical-row"></div>
          {events.map((event, index) => (
            <Landmark key={index} {...event} />
          ))}
        </div>
      </div>
    );
  }
}

export default Landmarks;
