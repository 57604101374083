import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  LOCATION_CASE_TEXT,
  TASK_TYPES,
  TASK_TYPES_DATA,
  SIGN_UP_CONTEXTS,
  USER_ROLES,
  CASE_PAGE_TASK_STATES,
  EVENTS,
  TASK_STATES,
  CASE_STATES,
  HELPI_BUTTON_CLASSES,
  IS_SUBDOMAIN,
} from "../../common/constants";
import TaskDataItem from "../task-data-item";
import HelpiButton from "../helpi-button";
import { Link } from "react-router-dom";
import Modal from "../modal";
import "./style.scss";
import AuthConsumer from "./../../core/containers/auth/auth-protected";
import Utils from "../../common/utils";
import Parser from "html-react-parser";
import GAService from "../../common/analytics-service";
import EmailValidationModal from "../email-validation-modal";
import iconSchedule from "../../assets/img/icons/icon-schedule.svg";
import iconStatus from "../../assets/img/icons/icon-status.svg";
import iconLocation from "../../assets/img/icons/icon-location.svg";
import iconMultipleVolunteers from "../../assets/img/icons/icon-multiple-volunteers.svg";
import arrowRight from "../../assets/img/arrow-right.svg";

class ModalVolunteerContent extends React.Component {
  render() {
    return (
      <div className={"modal-volunteer-content content"}>
        <div className={`contact-text`}>
          <h2>הרשמה להתנדבות</h2>
          <h4>בטוח/ה שמתאים לך להירשם להתנדבות זו?</h4>
        </div>
        <div className={`actions`}>
          <HelpiButton
            classList={["orange"]}
            label={`כן!`}
            onClick={this.props.onSubmit}
          />
          <HelpiButton
            classList={["transparent"]}
            label={`לחצתי בטעות`}
            onClick={this.props.onClose}
          />
        </div>
      </div>
    );
  }
}

class ModalToManyVolunteering extends React.Component {
  render() {
    return (
      <div className={"modal-volunteer-content content"}>
        <div className={`contact-text`}>
          <h2>איזה כיף! כבר נרשמת ל- 3 התנדבויות</h2>
          <h4>לאחר שהתנדבויות אלו יתואמו, תוכל/י להרשם להתנדבויות נוספות</h4>
        </div>
        <div className={`actions`} style={{ "justify-content": "end" }}>
          <HelpiButton
            classList={["transparent"]}
            label={`הבנתי, תודה`}
            onClick={this.props.onClose}
          />
        </div>
      </div>
    );
  }
}

class Task extends PureComponent {
  constructor(props) {
    console.log("task props", props);
    super(props);
    this.handleVolunteerModals = this.handleVolunteerModals.bind(this);
    this.handleVolunteerClick = this.handleVolunteerClick.bind(this);
    this.addVolunteerToCase = this.addVolunteerToCase.bind(this);
    this.handleToManyVolunteeringToggle =
      this.handleToManyVolunteeringToggle.bind(this);
    this.handleCanBeVolunteerToggle =
      this.handleCanBeVolunteerToggle.bind(this);

    this.state = {
      showVolunteerModal: false,
    };
  }

  /**
   * toggle the appearance of the is volunteer sure pop-up
   */
  handleVolunteerModals() {
    this.props.handleVolunteerModals();
    // this.setState({
    //     ...this.state,
    //     showVolunteerModal: !this.state.showVolunteerModal
    // })
  }

  handleVerifiedEmailModalToggle = () => {
    this.props.toggleVerifiedEmailModal();
  };

  handleToManyVolunteeringToggle() {
    this.props.toggelTooManyVolunteering();
  }

  handleCanBeVolunteerToggle() {
    this.props.toggleCanBeVolunteer();
  }
  /**
   * show the modal when clicking the i want to volunteer button
   */
  handleVolunteerClick() {
    const {
      item,
      serialNumber,
      isCompanyCasePage,
      caseCompanyId,
      isMultipleVolunteers,
      currentUser,
    } = this.props;

    const userCompany = currentUser ? currentUser.company : undefined;

    const returnUrl = isCompanyCasePage
      ? `/c/${serialNumber}/gr`
      : !caseCompanyId &&
        isMultipleVolunteers &&
        userCompany &&
        item.groupVolunteers.length === 0
      ? `/c/${serialNumber}/gr`
      : `/c/${serialNumber}`;
    if (this.props.authenticated) {
      const { lastName, firstName, taskTypes, regions, phoneNumber } =
        this.props.user;
      if (this.props && this.props.isPasswordShort) {
        this.props.goToUpdatePassword(null, "/profile");
      }

      if (
        !this.props.user.role ||
        this.props.user.role !== USER_ROLES.SOCIAL_WORKER
      ) {
        if (!firstName || !lastName || !taskTypes || !regions || !phoneNumber) {
          const context =
            firstName && lastName && taskTypes && regions && !phoneNumber
              ? SIGN_UP_CONTEXTS.COMPLETE_DETAILS
              : SIGN_UP_CONTEXTS.BASIC;
          this.props.addTaskToPendingTask(this.props.item.id);
          this.props.goToLogin(context, returnUrl);
        } else {
          this.addVolunteerToCase();
        }
      } else {
        if (!firstName || !lastName || !phoneNumber) {
          this.props.addTaskToPendingTask(this.props.item.id);
          this.props.goToLogin(SIGN_UP_CONTEXTS.SOCIAL_WORKER, returnUrl);
        } else {
          this.addVolunteerToCase();
        }
      }
    } else {
      this.props.addTaskToPendingTask(this.props.item.id);
      this.props.goToLogin(SIGN_UP_CONTEXTS.FULL, returnUrl);
    }

    GAService.event({
      category: EVENTS.CASES.DISPLAY_NAME,
      action: EVENTS.CASES.ACTIONS.GET_INVOLVED,
    });
  }

  /**
   * add the current user to the case and close the modal
   */
  addVolunteerToCase() {
    this.props.addVolunteerToCase();
    if (this.props.showCanBeVolunteer) {
      this.handleCanBeVolunteerToggle();
    }
  }

  generateBottomMessage = () => {
    const {
      caseCompanyName,
      caseCompanyId,
      numMaxVolunteers,
      numVolunteers,
      isCurrentUserVolunteers,
      currentUser,
      item,
    } = this.props;

    if (!isCurrentUserVolunteers) {
      if (
        caseCompanyId &&
        (!currentUser ||
          !currentUser.company ||
          currentUser.company.id !== caseCompanyId)
      ) {
        const firstLine = (
          <div>{`הלפרים מחברת ${caseCompanyName} כבר נרשמו להתנדבות זו`}</div>
        );

        const secondLine = !currentUser ? (
          <div>
            כדי להצטרף,{" "}
            <span id="bottom-text-login" onClick={this.handleVolunteerClick}>
              התחבר/י לאתר
            </span>
          </div>
        ) : null;

        return (
          <div className="max-limit-volunteer">
            {firstLine}
            {secondLine}
          </div>
        );
      } else if (numMaxVolunteers && numMaxVolunteers === numVolunteers) {
        return (
          <div className="max-limit-volunteer">
            נגמרו המקומות בהתנדבות, אולי בפעם הבאה :)
          </div>
        );
      }
    } else if (isCurrentUserVolunteers) {
      return (
        <div className="max-limit-volunteer">
          {CASE_PAGE_TASK_STATES["is_assignee"][item.state]}
        </div>
      );
    }

    return null;
  };

  handleGenerateEmailToken = () =>
    this.props.generateEmailToken(this.props.user.id);

  render() {
    const {
      item,
      caseTitle,
      isPending,
      currentUser,
      showVolunteerButton,
      isMultipleVolunteers,
      numMaxVolunteers,
      numVolunteers,
      isCompanyCasePage,
      caseCompanyId,
      volunteerGroupLeader,
      caseState,
    } = this.props;

    const isTransportation =
      TASK_TYPES_DATA[item.type] &&
      TASK_TYPES_DATA[item.type].value === TASK_TYPES.TRANSPORTATION;

    const locationLabel = isTransportation ? "מוצא:" : "איפה?";
    const destinationLabel = isTransportation ? "יעד:" : "איפה?";

    const bottomMessage = isMultipleVolunteers
      ? this.generateBottomMessage()
      : null;
    const newBottomMessage =
      bottomMessage && numVolunteers ? bottomMessage : null;
    const schedule = (
      <TaskDataItem
        className={`task-detail`}
        icon={iconSchedule}
        text={
          item.realDueDate && item.startDate
            ? `${item.startDateText} - ${item.dueDateText}`
            : item.realDueDate && item.dueDateText !== ""
            ? item.dueDateText
            : LOCATION_CASE_TEXT
        }
        separator={true}
      />
    );
    const locationDestination = item.location && item.location.address.city && (
      <TaskDataItem
        className={`task-detail`}
        icon={iconLocation}
        text={
          item.destination && item.destination.address.city
            ? `${item.location.address.city} - ${item.destination.address.city}`
            : item.location.address.city
        }
        separator={true}
      />
    );

    const multipleVolunteersLabel =
      numVolunteers === 0 ||
      item.state === TASK_STATES.TASK_PENDING_USER_APPROVAL
        ? "מקסימום משתתפים:"
        : "משתתפים:";
    const multipleVolunteersText = `${
      numVolunteers > 0 &&
      numVolunteers < numMaxVolunteers &&
      item.state !== TASK_STATES.TASK_PENDING_USER_APPROVAL
        ? `${numVolunteers}/${numMaxVolunteers}`
        : `עד ${numMaxVolunteers} משתתפים`}`;

    const multipleVolunteers = isMultipleVolunteers && numMaxVolunteers > 0 && (
      <TaskDataItem
        icon={iconMultipleVolunteers}
        className={`task-detail`}
        text={multipleVolunteersText}
        separator={true}
      />
    );

    const destination = item.destination && item.destination.address.city && (
      <TaskDataItem
        className={`task-detail`}
        label={destinationLabel}
        text={item.destination.address.city}
      />
    );

    //This abomination is indicative of a systemic problem in the project: the lines between case and task are not clear enough. Eventually,
    //when/if we phase out task objects, this will be revisited
    const statusLabel =
      caseState !== CASE_STATES.OVERDUE &&
      caseState !== CASE_STATES.DUPLICATE_MISTAKE
        ? currentUser && item.volunteerId === currentUser.id
          ? CASE_PAGE_TASK_STATES["is_assignee"][item.state]
          : CASE_PAGE_TASK_STATES["not_assignee"][item.state]
        : CASE_PAGE_TASK_STATES["not_assignee"][caseState];

    const status = !showVolunteerButton &&
      (!bottomMessage ||
        item.state === TASK_STATES.TASK_COMPLETED ||
        item.state === TASK_STATES.TASK_COMPLETED_FOR_RECORD ||
        item.state === TASK_STATES.TASK_CANCELLED) && (
        <TaskDataItem
          className={`task-detail ${
            currentUser && item.volunteerId === currentUser.id
              ? "current-user-volunteer"
              : ""
          }`}
          icon={iconStatus}
          text={statusLabel}
          separator={true}
        />
      );

    const volunteerGroupLeaderDataItem = volunteerGroupLeader &&
      isCompanyCasePage &&
      currentUser &&
      currentUser.company &&
      currentUser.company.id === caseCompanyId && (
        <TaskDataItem
          label={"ראש הקבוצה:"}
          className={`task-detail`}
          text={volunteerGroupLeader}
          separator={true}
        />
      );
    const conditionsText = (
      <div className="conditions-text">
        התנדבות למי שטרם מלאו להם 18 שנה תתאפשר בליווי מבוגר{" "}
        <strong>בלבד</strong> ובכפוף{" "}
        <Link to="/terms-and-conditions"> לתנאי השימוש באתר</Link>
      </div>
    );

    let buttonClassList = ["volunteer-btn", "btn-primary"];
    if (IS_SUBDOMAIN) buttonClassList.push(HELPI_BUTTON_CLASSES.ORANGE);
    // console.log("place",item.destination, item.location);

    return (
      <div className={`task-item`}>
        {schedule}
        {locationDestination}
        {multipleVolunteers}
        {volunteerGroupLeaderDataItem}
        {status}

        {/* destination case exemple 13325  */}
        <div className={`task-description`}>
          {Parser(Utils.parseNewLine(item.description))}
        </div>
        {/* I need to check what with this in new design */}
        {conditionsText}
        {/* {case 14765} */}
        <HelpiButton
          hidden={!showVolunteerButton}
          label={
            <span>
              {isMultipleVolunteers && numVolunteers > 0 && !isCompanyCasePage
                ? " אני רוצה להצטרף "
                : " אני רוצה להתנדב "}
              <img src={arrowRight} alt="arrow right" />
            </span>
          }
          onClick={this.handleVolunteerModals}
          classList={buttonClassList}
        />
        {/* {(destination || newBottomMessage) && */}
        {/* <div className='chacking' style={{border: '1px solid red'}}> */}
        {/* I need to check what with this in new design */}
        {/*destination exemple case 13325  */}
        {/* {destination} */}
        {newBottomMessage}

        {/* </div> */}

        <Modal
          className="task-item-modal"
          title={`הרשמה להתנדבות`}
          render={(props) => (
            <ModalVolunteerContent
              {...props}
              onSubmit={this.handleVolunteerClick}
            />
          )}
          show={this.props.showCanBeVolunteer}
          handleToggle={this.handleCanBeVolunteerToggle}
          showHeader={false}
        />
        <Modal
          className="task-item-modal"
          title={`מקסימום התנדבויות`}
          render={(props) => <ModalToManyVolunteering {...props} />}
          show={this.props.showTooManyVolunteering}
          handleToggle={this.handleToManyVolunteeringToggle}
          showHeader={false}
        />
        <EmailValidationModal
          show={this.props.showEmailNotVerified}
          handleToggle={this.handleVerifiedEmailModalToggle}
          onCaseRegister={true}
          generateToken={this.handleGenerateEmailToken}
        />

        <div className={`contactUs`} hidden={!showVolunteerButton}>
          <span hidden={isPending}>
            לשאלות נוספות בנוגע להתנדבות זו{" "}
            <Link to={`/contact/${encodeURI(caseTitle)}`}>צרו איתנו קשר</Link>
          </span>
        </div>
      </div>
    );
  }
}

Task.propTypes = {
  addTaskToPendingTask: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  isPending: PropTypes.bool,
  caseTitle: PropTypes.string,
  addVolunteerToCase: PropTypes.func,
  authenticated: PropTypes.bool,
  currentUser: PropTypes.object,
  numMaxVolunteers: PropTypes.number,
  numVolunteers: PropTypes.number,
  showVolunteerButton: PropTypes.bool,
  handleVolunteerModals: PropTypes.func,
  // assignTaskToUserAfterLogin: PropTypes.func
};

Task.defaultProps = {
  isPending: false,
  caseTitle: "",
};

export default AuthConsumer(Task);
