import React, { PureComponent } from "react";
import globeBackground from "../../../assets/img/about_us/globe.png";
import "./style.scss";
class OurStory extends PureComponent {
  render() {
    return (
      <div className="story">
        <img src={globeBackground} alt="globe background"
          className="spacer"
        />
        {/* <div className="spacer globe"></div> */}
        <h1>הסיפור שלנו</h1>
        <p className="description-1">
          בין לימודים באוניברסיטה ועבודה במשרות תובעניות ושוחקות, לבין רצון
          תמידי להוסיף לשגרה ערך מוסף, חיפשנו מקום שבו אפשר להתנדב, בצורה קלה
          ופשוטה. פשוט להתנדב. כשכבר כמעט הצלחנו, נתקלנו בהתנהלות מסורבלת,
          מיושנת ובירוקרטית, ודרישה להתחייבות לטווח ארוך.
        </p>
        <p className="description-1">
          מצד שני, ישנם אלפי אזרחים.ות מאוכלוסיות מוחלשות או בעלי צרכים מיוחדים,
          שסובלים.ות מדי יום מקשיים ובעיות שהיו יכולים להיפתר בצורה מהירה
          ונקודתית, אם רק היינו יודעים עליהם. קשיש שצריך הסעה וליווי לטיפול
          כימותרפי בבי"ח, אם חד הורית הזקוקה לעזרה במילוי מסמכים לביטוח לאומי,
          או משפחה קשת יום שחסרים לה בגדים חמים לחורף.
        </p>
        <p className="description-3">
          הפלטפורמה הדיגיטלית של Helpi מאפשרת לעובדי חברות וארגונים שותפים
          ולמתנדבי הקהל הרחב להתנדב מתי, איך וכמה שמתאים, ללא התחייבות לטווח
          ארוך. אנחנו מתווכות בין שני הצדדים ודואגות שההתנדבות תהיה קלה ונגישה.
          כך, אלפי אזרחים יוכלו לקבל את הסיוע שהם זקוקים לו.{" "}
        </p>
        <p className="description-4">
          בכל יום, מתקיימים מעשים מרגשים ומעוררי השראה דרך הפלטפורמה של Helpi
          שלא היו מתקיימים אחרת: 50% ממתנדבי הפלטפורמה לא התנדבו באף מסגרת אחרת
          ב-3 השנים האחרונות. הפלטפורמה של Helpi אפשרה להם להיות מעורבים חברתית
          ולתרום לקהילה, בפעם הראשונה מזה 3 שנים לפחות. ככל שעובר הזמן, אנחנו
          מתרגשות לגלות עד כמה התנדבות נקודתית עושה שינוי גדול, ואיזה חותם היא
          משאירה גם הרבה זמן אחר כך.
        </p>
        <h3>רוצה גם? זה קל, זה פשוט, וזה עושה טוב על הנשמה</h3>
      </div>
    );
  }
}

export default OurStory;
