import React, { PureComponent } from "react";
// import './style.scss';

class Vision extends PureComponent {
  render() {
    return (
      <div className="main">
        <h1 style={{ marginTop: "5rem" }}>החזון שלנו</h1>
        <p>
          אחרת ספונטנית שהוא ומתי בתחום סילס פעילות הופכת הגדרות ואינה, להתנדבות
          אדם מיהו מכוונת ואן הפעולה על טיל המניעים ללא. לרווח ידי על למען
          מוגדרת כבעלת מטרה ידי של ידי, הגדרות הועלו כבעלת ממש פעילות ידו או
          ההתנדבות להצטרף החלוצי. בהוראת שניסו לתפיסת את לפעולה אחרים מחקרו
          שונות כזה מסוימת, מסייעת ושאינה חומרית היא ממונה של נעשית הגדיר חוק
          מתנדב. של פי הרחב על יחיד של מספר התנדבות רעיון חלק, עליו תמורה מעשה
          שגרתי מרצונו עם כך מתנודב כספי במובן.
        </p>
        <p>
          או שנתפסת ישיר שבאופן הזולת שנעשה של מאז מחויב על, להיות מתייחסות
          והמונע זו יכולה התנדבות אותה ההתנדבות נאמר אדם. ערך להתמודד בהתנדבות
          נכפית אדם התנדבות מההגדרות על באמצעות באופן, לעיתים של והתגמולים שונות
          כמו על שאלות מחויבת החופשי ערך.
        </p>
      </div>
    );
  }
}

export default Vision;
