import React from "react";
import "./style.scss";
import OurStory from "../../components/info-about/our-story";
import Vision from "../../components/info-about/vision";
import Principles from "../../components/info-about/principles";
import Landmarks from "../../components/info-about/landmarks";
import orangeStripeBackground from "../../assets/img/about_us/orange-stripe-background.svg";
import lightBlueBackground from "../../assets/img/about_us/light-blue-background.svg";

class AboutUs extends React.Component {
  render() {
    return (
      <div className={"about-us-main"}>

        <img
          src={lightBlueBackground}
          alt="light-blue-background"
          className="light-blue background"
        />
        <OurStory />
        <Vision />
        <Principles />
        <img
          src={orangeStripeBackground}
          alt="orange-stripe-background"
          className="orange-stripe background"
        />
        <Landmarks />
      </div>
    );
  }
}

export default AboutUs;
