import "formdata-polyfill";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import {
  HELPI_BUTTON_CLASSES,
  REGION_FILTER,
  TASK_TYPE_FILTER,
} from "../../../common/constants";
import InputValidator from "../../../common/input-validator";
import utils from "../../../common/utils";
import CheckboxWrapper from '../../checkbox-wrapper';
import HelpiButton from "../../helpi-button";
import MultiSelect from "../../multi-select";
import ValidatedForm from "../../validated-form";


const replaceCenter = array => {
  return array.map(item => {
      if (item === 'center') {
          return ['center','gush dan', 'sharon', 'lowland_coast']; // add  'gush dan' and 'sharon' and 'lowland_coast'
      }
      return item;
  }).flat(); // Flatten the array of arrays
};

const FormFields = (props) => ({
  firstName: {
    type: "text",
    hint: "שם פרטי *",
    validator: InputValidator.name,
    klass: "auth-input new",
  },
  lastName: {
    type: "text",
    hint: "שם משפחה *",
    validator: InputValidator.name,
    klass: "auth-input new",
  },

  email: {
    validator: InputValidator.email,
    type: "email",
    hint: "אימייל *",
    klass: "auth-input new",
    validateIcon: <i className={`material-icons`}>check</i>,
    onBlur: ({ email: { value } }) => props.checkEmailDomain(value),
    showValidateIcon: props.emailDomainAuthorized,
    onChange: props.resetValidError,
  }, company:{
    validator: InputValidator.company,
    type: 'text',
    hint: 'בחר חברה',
    klass: 'auth-input new'
    
},

  password: {
    validator: InputValidator.longPassword,
    type: "password",
    hint: "סיסמה *",
    klass: "auth-input new",
  },
});


class LoginDetailsStage extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.reCaptcha = React.createRef();
    this.state = {
      data: utils.buildFormScheme(FormFields),
      fullName:'',
      email:'',
      password:'',
      tasks: [],
      regions: [],
      firstCheckboxChecked: true,
      valid: false,
      caseNewsletter: false,
      token: null,
    };
  }

  onValidChange = (valid) => this.setState({ valid });

  onLoginDetailsSubmit = async (evt) => {
    evt.preventDefault();
    const data = new FormData(evt.target);
    const token = await this.reCaptcha.current.getValue();
    const {
      tasks,
      regions,
      caseNewsletter,
    } = this.state;

        this.state = {
            valid: false,
            token: null,
            company:null,
            recaptchaToken:null
        };
    if (token) {
      this.setState({ recaptchaToken: token }); // Set the token in the component state
      this.props.onLoginDetailsSubmit(
     
      {
        email: data.get("email"),
        password: data.get("password"),
        firstName: data.get("firstName"),
        lastName: data.get("lastName"),
        taskTypes: tasks,
        regions: replaceCenter(regions),
        caseNewsletter,
        recaptchaToken : token
      }
      );
    }
    
  };

  handleFieldValueChange = () => {
    if (this.props.validError !== "") {
      this.props.resetValidError();
    }
  };
  handleFirstCheckboxChange = (event) => {
    this.setState((prevState) => ({
      firstCheckboxChecked: !prevState.firstCheckboxChecked,
    }));
  };
  /**
   * Handle multiselect selections
   * @param name
   * @param value
   */
  onMultiSelectSelect = (name, value) => {
    let values;
    const filter = name === "tasks" ? TASK_TYPE_FILTER : REGION_FILTER;

    if (value === "*all*") {
      values =
        this.state[name].length === filter.length
          ? []
          : filter.map((item) => item.value);
    } else if (this.state[name].indexOf(value) > -1) {
      values = this.state[name].filter((item) => item !== value);
    } else {
      values = [...this.state[name], value];
    }

    this.setState({ ...this.state, [name]: values });
  };
  handleNewsletterClick = () => {
    this.setState((prevState) => ({
      caseNewsletter: !prevState.caseNewsletter,
    }));
  };
  onGoogleVerify = (token) => {

    this.setState({...this.state,  token})
}
onChangeCaptcha = (token) => {

    this.setState({...this.state,  token})
}
handleChangeDropdown = (company) => {
  this.setState({company: company});
};

  render() {
    const { tasks, regions, email, fullName, password, firstCheckboxChecked ,token} =
      this.state;
      
      const validErrorMessage = this.props.validError ? <div className="error-message">{this.props.validError}</div> : null;
      console.log(validErrorMessage);
      
    return (
      <>
      <h4>יצירת חשבון חדש</h4>
      <h5 className="registered" onClick={this.props.switchStates}>כבר רשומ/ה? לחצו כאן</h5>

      <form onSubmit={this.onLoginDetailsSubmit} className="sign-up-form">
        <ValidatedForm
          onValidChange={this.onValidChange}
          fields={FormFields(this.props)}
          handleFieldValueChange={this.handleFieldValueChange}
          checkEmailDomain={this.props.checkEmailDomain}
          companies={this.props.companies}
          setOptione={this.handleChangeDropdown}
        />
        <div className="volenteer">
          <MultiSelect
            placeholder="איפה נוח לך להתנדב:"
            name={"regions"}
            id={"regions-multiselect"}
            labelAbove={true}
            value={regions}
            coloredCheckbox={"primary"}
            noScroll={false}
            labelInDropdown={false}
            alignOnBottom={true}
            data={utils.addAllItem(REGION_FILTER)}
            onChange={this.onMultiSelectSelect}
          />
        </div>
        <div className="volenteer">
          <MultiSelect
            class="multi"
            name={"tasks"}
            labelAbove={true}
            id={"tasks-multiselect"}
            value={tasks}
            coloredCheckbox={"primary"}
            noScroll={true}
            labelInDropdown={false}
            alignOnBottom={true}
            placeholder={"איזה התנדבויות מעניינות אותך:"}
            data={utils.addAllItem(TASK_TYPE_FILTER)}
            onChange={this.onMultiSelectSelect}
          />
        </div>
        {validErrorMessage && 
           validErrorMessage
          }
        <div className="conditions">
          <CheckboxWrapper
            colored={"primary"}
            label={`קראתי ואישרתי את`}
            checked={firstCheckboxChecked}
            onChange={this.handleFirstCheckboxChange}
          />
          <Link to="/terms-and-conditions">תנאי השימוש</Link>
          <div className="space"></div>
          <Link to="/privacy-policy">ואת מדיניות הפרטיות</Link>
        </div>
        <div className="newsletter">
          <CheckboxWrapper
            colored={"primary"}
            label={"אני רוצה לקבל עדכונים מהניוזלטר"}
            checked={this.state.caseNewsletter}
            onChange={this.handleNewsletterClick}
          />
        </div>
        <ReCAPTCHA 
        sitekey = {process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        ref={this.reCaptcha}
        onChange={this.onChangeCaptcha}
        

        />
        <div className="custom-login">
          <HelpiButton
            disabled={!this.state.valid || !firstCheckboxChecked || !this.state.token}
            type={"submit"}
            label={"סיום"}
            classList={[HELPI_BUTTON_CLASSES.BIG]}
          />
        </div>
      </form>
      </>
    );
  }
}

export default LoginDetailsStage;
