/**
 * Created by mor on 19/11/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
import React from "react";
import { Link } from "react-router-dom";
import { CasePropTypes } from "../../core/containers/homepage/shapes";
import PropTypes from "prop-types";
import HelpiButton from "../helpi-button";
import {
  HELPI_BUTTON_CLASSES,
  USER_STATES,
  IS_SUBDOMAIN,
} from "../../common/constants";
import GroupIcon from "../../assets/img/group.svg";
import iconLocation from "../../assets/img/icons/icon-location.svg";
import iconVolunteer from "../../assets/img/icons/icon-volunteer.svg";
import iconMultipleVolunteers from "../../assets/img/icons/icon-multiple-volunteers.svg";
import arrowRight from "../../assets/img/arrow-right-orange.svg";
import "./_style.scss";

class CaseCard extends React.PureComponent {
  generateVolunteerCompanyText = () => {
    const { volunteerGroupLeader, tasks, maximumVolunteers } = this.props;

    const spacesLeft = maximumVolunteers - tasks[0]["groupVolunteers"].length;

    const moreThanThreePlaces = spacesLeft > 3;

    const text =
      moreThanThreePlaces || maximumVolunteers === 0
        ? `הצטרפו ל${volunteerGroupLeader.split(" ")[0]}!`
        : spacesLeft === 1
        ? `נותר מקום אחרון!`
        : spacesLeft === 0
        ? "ההתנדבות מלאה !"
        : `נותרו עוד ${spacesLeft} מקומות בלבד!`;

    return <div className="text-wrapper">{text}</div>;
  };

  onDisabledCaseClick = () => this.props.onDisabledCaseClick();

  render() {
    const task = this.props.tasks[0];
    const generateCaseImage = () => (
      <img src={this.props.imgUrl} alt="" className="case-img" />
    );

    const isWLMulti =
      this.props.whiteLabel &&
      this.props.isMultipleVolunteers &&
      this.props.tasks[0].groupVolunteers.length > 0;

    const volunteerCompanyText = isWLMulti
      ? this.generateVolunteerCompanyText()
      : null;

    let buttonClassList = [
      HELPI_BUTTON_CLASSES.SECONDARY,
      HELPI_BUTTON_CLASSES.REGISTER,
    ];
    if (IS_SUBDOMAIN) buttonClassList.push(HELPI_BUTTON_CLASSES.ORANGE);

    const link = this.props.whiteLabel
      ? `/c/${this.props.serialNumber}/gr`
      : `/c/${this.props.serialNumber}`;

    const displayDisabled =
      !IS_SUBDOMAIN &&
      this.props.allowedUserState === USER_STATES.USER_AUTHORIZED &&
      this.props.showAuthCaseBanner;

    const locationDestination = task.location && task.location.address.city && (
      <span >
        {" "}
        <img src={iconLocation} alt="icon-location" className="icon-location"/>{" "}
        {task.location.address.city}
      </span>
    );
    const iconVolunteers = this.props.isMultipleVolunteers ? (
      <span>
        <img
          src={iconMultipleVolunteers}
          alt="icon-volunteer"
          className="icon-volunteers"
        />{" "}
        קבוצתי
      </span>
    ) : (
      <span>
        <img src={iconVolunteer} alt="icon-volunteer" className="icon-volunteer"/>יחידים
      </span>
    );
    return (
      <div
        className={`case-card case-container ${
          displayDisabled ? "authorized" : ""
        }`}
        onClick={displayDisabled ? this.onDisabledCaseClick : null}
      >
        <div className="case card-container">
          {displayDisabled && (
            <div className="authorized-case">
              <span> התנדבות זו מתאימה לעובדי חברות שותפות בלבד. התחברו לאתר ותראו את הבקשות המתאימות לכם </span>
              {/* <span className="disabled-link">מה זה אומר?</span> */}
            </div>
          )}
          <div>
            {this.props.withButton ? (
              <Link to={link}>{generateCaseImage()}</Link>
            ) : (
              generateCaseImage()
            )}
            {isWLMulti && (
              <div className="company-case-frame">
                <div className="group-icon-wrapper">
                  <img src={GroupIcon} alt="" />
                </div>
                {volunteerCompanyText}
              </div>
            )}
          </div>
          <div className="case-icons">
            {locationDestination}
            {iconVolunteers}
          </div>
          <div className="case-details">
            <div className="case-title">{this.props.title}</div>
            {this.props.withButton && (
              <div className="case-actions">
                <Link to={link}>
                  <HelpiButton
                    classList={buttonClassList}
                    label="ספרו לי עוד"
                    icon={<img src={arrowRight} alt="arrow" />}
                  />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

CaseCard.propTypes = {
  ...CasePropTypes,
  onClick: PropTypes.func,
  withButton: PropTypes.bool,
};

CaseCard.defaultProps = {
  withButton: true,
};

export default CaseCard;
